import { followingElem, headerElem, leadingElem, menuToggler, timelineInSeconds } from '../data/constants';

function getPageTransition(gsap, options) {
	const windowWidth = window.innerWidth;

	if (windowWidth < 700) {
		if (options?.mobileMenu) {
			return gsap
				.timeline({ paused: true })
				.fromTo(
					leadingElem,
					timelineInSeconds * 0.8,
					{ scaleY: 0 },
					{ scaleY: 1, transformOrigin: 'bottom', ease: Power4.easeInOut }
				)
				.fromTo(
					followingElem,
					timelineInSeconds * 0.8,
					{ scaleY: 0 },
					{ scaleY: 1, transformOrigin: 'bottom', ease: Power4.easeInOut },
					0.07
				);
		}
		return gsap
			.timeline({ paused: true })
			.fromTo(
				leadingElem,
				timelineInSeconds * 0.8,
				{ scaleY: 0 },
				{ scaleY: 1, transformOrigin: 'top', ease: Power4.easeInOut }
			)
			.fromTo(
				followingElem,
				timelineInSeconds * 0.8,
				{ scaleY: 0 },
				{ scaleY: 1, transformOrigin: 'top', ease: Power4.easeInOut },
				0.07
			);
	} else {
		return gsap
			.timeline({ paused: true })
			.fromTo(leadingElem, timelineInSeconds, { scaleX: 0 }, { scaleX: 1, transformOrigin: 'left', ease: Power4.easeInOut })
			.fromTo(
				followingElem,
				timelineInSeconds,
				{ scaleX: 0 },
				{ scaleX: 1, transformOrigin: 'left', ease: Power4.easeInOut },
				0.07
			);
	}
}

export function removeTransition(gsap, target) {
	const transition = getPageTransition(gsap);
	if (!transition) return;

	if (target) {
		return gsap.set(target, { clearProps: true }); // clear all values added to an element style
	}

	transition.clear();
}

export function playTransition(gsap, options) {
	headerElem.style.opacity = '0';
	menuToggler.style.opacity = '0';

	let transition = getPageTransition(gsap);

	if (options) {
		if (options.direction === 'reverse') {
			return transition.reverse(0);
		} else if (options.mobileMenu) {
			transition = getPageTransition(gsap, { mobileMenu: options.mobileMenu });
		}
	}

	return transition.play(0);
}

