import {
	headerElem,
	homeProjectButtons,
	pageTransitionDuration,
	projectPageNextButtons,
	contentElem,
	sendEmailElem,
	projectsData,
	pageSections,
	navLinks,
	appElem,
	menuToggler,
	mobileMenu,
	backToTop,
	ageElem,
	fullSiteLinksObj,
} from './app/data/constants';

import { playTransition, removeTransition } from './app/animations/transitions';
import { revealImages, revealText } from './app/animations/reveal';
import { asscroll, enableScroll, setGsapScroller } from './app/scroll';

const isMobile = window.innerWidth < 700;

function createMenuLinkDivider() {
	const dividerElem = document.createElement('div');
	dividerElem.id = 'menuLinkDivider';
	dividerElem.classList.add('divider');
	dividerElem.style.opacity = '0';
	dividerElem.style.background = '#f2f4f3';
	dividerElem.style.height = '3px';
	dividerElem.style.width = '0px';

	return dividerElem;
}

function removeMenuLinkDivider() {
	const activeMenuLink = document.querySelector('.nav-item__active');
	const activeLiElem = activeMenuLink.parentElement;
	const divider = document.querySelector('#menuLinkDivider');
	activeLiElem.removeChild(divider);
}

function handleActiveLinks(isMenuOpen) {
	const dividerElem = createMenuLinkDivider();

	navLinks.forEach((link) => {
		const { linkUrl } = link.dataset;
		const productionLink = linkUrl.concat('/');

		if (!link.classList.contains('site-name')) {
			if (
				window.location.pathname === linkUrl ||
				window.location.pathname === productionLink ||
				(window.location.pathname.startsWith('/projects') && linkUrl === '/projects')
			) {
				link.classList.add('nav-item__active');

				if (isMobile && isMenuOpen) {
					if (link.classList.contains('mobile-link')) {
						const linkLiElem = link.parentElement;

						if (!mobileMenu.contains(dividerElem)) linkLiElem.append(dividerElem);

						setTimeout(() => {
							dividerElem.style.opacity = '1';
							dividerElem.style.width = '100%';
						}, pageTransitionDuration * 0.5);
					}
				}
			}
		}
	});
}

function revealMobileMenu(isMenuOpen) {
	mobileMenu.classList.add('open');
	handleActiveLinks(isMenuOpen);
	mobileMenu.style.opacity = '1';
	menuToggler.textContent = 'close';
	revealText({ targets: '.mobile-link', startDelay: 0.07, mobileMenu: true });
}

function hideMobileMenu() {
	removeMenuLinkDivider();
	mobileMenu.classList.remove('open');
}

function toggleMobileMenu() {
	let isMenuOpen = false;

	setTimeout(() => {
		headerElem.style.opacity = '1';
		menuToggler.style.opacity = '1';
	}, pageTransitionDuration * 0.8);

	if (!mobileMenu.classList.contains('open')) {
		playTransition(gsap, { mobileMenu: true }); // play page transition

		return setTimeout(() => {
			isMenuOpen = true;
			revealMobileMenu(isMenuOpen);
		}, pageTransitionDuration * 0.8);
	}

	mobileMenu.style.opacity = '0';
	menuToggler.textContent = 'menu';

	removeTransition(gsap);

	setTimeout(() => {
		isMenuOpen = false;
		hideMobileMenu(isMenuOpen);
	}, 400);
}

function handleSendEmailClick() {
	if (
		window.location.pathname === '/' ||
		(process.env.NODE_ENV === 'production'
			? window.location.pathname === '/contact/'
			: window.location.pathname === '/contact')
	) {
		sendEmailElem.addEventListener('click', (event) => {
			navigator.clipboard.writeText(event.target.textContent);

			const copiedTextElem = document.querySelector('.email-copied');

			copiedTextElem.style.opacity = '1';

			const timer = 5000;

			setTimeout(() => {
				copiedTextElem.style.opacity = '0';
			}, timer);
		});
	}
}

function getCurrentAge() {
	const ageInMilliseconds = new Date() - new Date('1993-11-06');
	const currentAge = Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);

	if (
		window.location.pathname === '/' ||
		(process.env.NODE_ENV === 'production' ? window.location.pathname === '/about/' : window.location.pathname === '/about')
	) {
		ageElem.textContent = currentAge;
	}
}

function handlePagesTransitions(linkArr, linkTypes) {
	const timeOutDelay = isMobile ? pageTransitionDuration : pageTransitionDuration * 1.3;

	linkArr.forEach((link) => {
		link.addEventListener('click', () => {
			if (linkTypes === 'navLinks') {
				if (isMobile && mobileMenu.classList.contains('open')) {
					return window.location.assign(link.dataset.linkUrl);
				}
			}

			playTransition(gsap); // play page transition

			setTimeout(() => {
				if (linkTypes === 'homeProjectButtons' || linkTypes === 'projectPageNextButtons') {
					window.location.assign(link.dataset.projectUrl);
				} else if (linkTypes === 'navLinks') {
					window.location.assign(link.dataset.linkUrl);
				}
			}, timeOutDelay);
		});
	});
}

function handleClickEvents() {
	for (const linksKey in fullSiteLinksObj) {
		handlePagesTransitions(fullSiteLinksObj[linksKey], linksKey);
	}

	if (isMobile) {
		menuToggler.addEventListener('click', toggleMobileMenu);
	}

	if (!isMobile && backToTop) {
		backToTop.addEventListener('click', asscroll.scrollTo(0));
	}

	handleSendEmailClick();
}

function init() {
	gsap.registerPlugin(ScrollTrigger);

	enableScroll();

	setTimeout(() => {
		setGsapScroller();
		playTransition(gsap, { direction: 'reverse' }); // play reversed page transition
	}, 100);

	handleActiveLinks();
	handleClickEvents();
	getCurrentAge();

	const opacityDelay = isMobile ? pageTransitionDuration * 0.5 : pageTransitionDuration * 0.7;

	setTimeout(() => {
		appElem.style.opacity = '1';
		headerElem.style.opacity = '1';
		menuToggler.style.opacity = '1';

		revealText({ targets: '.smooth-container' });
		revealImages({ targets: '.img-container' });
	}, opacityDelay);

	contentElem.style.padding = isMobile ? `0 1rem 0` : '0 3rem 0 3rem';
	contentElem.style.willChange = 'transform';

	pageSections.forEach((section) => {
		section.style.marginBottom = '5vh';
	});

	projectsData.forEach((project) => {
		if (project.url === window.location.pathname) {
			contentElem.style.padding = '0';

			if (window.innerWidth > 700) {
				enableScroll('horizontal');
			}
		}
	});
}

document.addEventListener('DOMContentLoaded', init);

