import { pageTransitionDuration } from './data/constants';

export let asscroll;

export function enableScroll(axis) {
	if (window.innerWidth > 700) {
		if (!axis) {
			asscroll = new ASScroll({
				containerElement: document.querySelector('#app'),
				ease: 0.055,
				touchEase: 0.055,
				touchScrollType: 'transform',
			});
			return asscroll.enable();
		}

		if (axis === 'horizontal') {
			return setTimeout(() => {
				asscroll.disable();
				asscroll.enable({
					ease: 0.055,
					touchEase: 0.055,
					touchScrollType: 'transform',
					horizontalScroll: true,
				});
			}, pageTransitionDuration * 1.3);
		}
	} else {
		document.querySelector('html').style.scrollBehavior = 'smooth';
	}
}

export function setGsapScroller() {
	if (asscroll) {
		ScrollTrigger.defaults({
			scroller: asscroll.containerElement,
		});

		ScrollTrigger.scrollerProxy(asscroll.containerElement, {
			scrollTop(value) {
				return arguments.length ? (asscroll.currentPos = value) : asscroll.currentPos;
			},
			getBoundingClientRect() {
				return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
			},
		});

		asscroll.on('update', ScrollTrigger.update);
		ScrollTrigger.addEventListener('refresh', asscroll.resize);
	}
}

