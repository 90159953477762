import Splitting from 'splitting';

// gsap.registerPlugin(ScrollTrigger);

const defaultStartDelay = 0.2; // (s)

export function revealText(options) {
	const revealedText = document.querySelectorAll(options.targets);
	const results = Splitting({ target: revealedText, by: 'lines' });
	const animDuration = options.mobileMenu ? 0.2 : 0.5;

	results.forEach((splitResult) => {
		const wrappedLines = splitResult.lines
			.map((wordsArr) => {
				return `
                    <span class="word-line">
                        <div class="words">
                            ${wordsArr
								.map((word) => {
									return `${word.outerHTML}<span class="whitespace"> </span>`;
								})
								.join('')}
                        </div>
                    </span>`;
			})
			.join('');
		splitResult.el.innerHTML = wrappedLines;
	});

	revealedText.forEach((textContainer, i) => {
		const lines = textContainer.querySelectorAll('.word-line .words');

		gsap.timeline({
			scrollTrigger: {
				trigger: textContainer,
				start: 'top 100%',
			},
		})
			.set(revealedText, { autoAlpha: 1 })
			.from(lines, animDuration, {
				yPercent: 100,
				ease: options.mobileMenu ? Power2.ease : Power4.out,
				stagger: 0.1,
				delay: options.startDelay ? i * options.startDelay : defaultStartDelay,
			});
	});
}

export function revealImages(options) {
	const revealedImages = document.querySelectorAll(options.targets);
	const animDuration = 2; // sec

	revealedImages.forEach((imgContainer, i) => {
		const targetImages = imgContainer.querySelectorAll('.img-ghost');

		gsap.timeline({
			scrollTrigger: {
				trigger: imgContainer,
				start: 'top 130%',
			},
		})
			.set(revealedImages, { autoAlpha: 1 })
			.to(targetImages, animDuration, {
				xPercent: 105,
				ease: Expo.easeInOut,
				stagger: 0.1,
				// for project page slider imgs
				// delay: i * 0.25,
				delay: options.startDelay ? i * options.startDelay : defaultStartDelay,
			});
	});
}

