export const headerElem = document.querySelector('[data-view="header"]');
export const followingElem = document.querySelector('[data-transition="following"]');
export const leadingElem = document.querySelector('[data-transition="leading"]');
export const homeProjectButtons = document.querySelectorAll('[data-to-project]');
export const projectPageNextButtons = document.querySelectorAll('[data-next-project]');
export const appElem = document.querySelector('#app');
export const contentElem = document.querySelector('#content');
export const sendEmailElem = document.querySelector('#send-email');
export const ageElem = document.querySelector('#age');
export const pageSections = document.querySelectorAll('[data-page]');
export const projectAssets = document.querySelectorAll(['.project-page__asset', '.project-preview__img', '.about__picture']);
export const menuToggler = document.querySelector('[data-menu-toggler]');
export const togglerLines = document.querySelectorAll('[data-toggler-line]');
export const mobileMenu = document.querySelector('[data-mobile-menu]');
export const navLinks = document.querySelectorAll('[data-link-url]');
export const backToTop = document.querySelector('[data-to-top]');

export const projectsData = [
	{ name: 'project1', url: '/projects/libngo.html' },
	{ name: 'project2', url: '/projects/rapidcoin.html' },
	{ name: 'project3', url: '/projects/bandify.html' },
	{ name: 'project4', url: '/projects/hexadesign.html' },
];

export const routes = [
	{ name: 'home', url: '/' },
	{ name: 'projects', url: process.env.NODE_ENV === 'production' ? '/projects/' : '/projects' },
	{ name: 'about', url: process.env.NODE_ENV === 'production' ? '/about/' : '/about' },
	{ name: 'contact', url: process.env.NODE_ENV === 'production' ? '/contact/' : '/contact' },
];

export const fullSiteLinksObj = {
	homeProjectButtons: homeProjectButtons,
	projectPageNextButtons: projectPageNextButtons,
	navLinks: navLinks,
};

export const timelineInSeconds = 1.3;
const oneMilliseconds = 1000;
export const pageTransitionDuration = timelineInSeconds * oneMilliseconds;

